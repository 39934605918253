export default {
  namespaced: true,
  state: {
    //apiUrl: "http://localhost:8000/api/",
    //
    apiUrl: "https://operatic-api.cetemin.edu.pe/api/",
    //apiUrl: "http://192.168.100.242:8000/api/",
    //apiUrl: "http://172.23.14.42:8000/api/",
    configAxios: {
      method: "",
      url: "",
      responseType: "",
      data: {},
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
    configAxiosDefault: {
      method: "",
      url: "",
      responseType: "",
      data: {},
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },

    sedes: [
      {
        text: "ABQ",
        value: "ABQ",
      },
      {
        text: "IRQ",
        value: "IRQ",
      },
      {
        text: "FCHB",
        value: "FCHB",
      },
      {
        text: "CIEMAM",
        value: "CIEMAM",
      },

    ],
  },

  mutations: {
    resetConfigAxios(state) {
      state.configAxios = Object.assign({}, state.configAxiosDefault);
    },
  },
};
