<template>
    <v-card elevation="0" :class="classWrapper">
        <v-card-text class="black--text">
            <v-form v-model="validateform" :disabled="loadingadd">
            <v-row>
                <v-col cols="12" class="py-1">
                    <div> REGISTRAR NUEVO PACIENTE </div>
                    <div><b class="red--text text-h6">*</b>obligatorio</div>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>Tipo:<b class="red--text text-h6">*</b></label>
                        <v-select class="white" dense outlined hide-details="auto" v-model="paciente.tipo"
                            :rules="[rules.required]" :items="['colaborador', 'estudiante']"></v-select>
                    </div>
                </v-col>

                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>DNI<b class="red--text text-h6">*</b></label>
                        <v-text-field :loading="loadingFindDNI" @keypress.enter="getApiData()" :rules="[rules.required]"
                            class="white" dense outlined hide-details="auto" v-model="paciente.dni">
                            <template v-slot:append>
                                <v-btn :loading="loadingFindDNI" @click="getApiData()" icon small class="white"
                                    elevation="0"><v-icon>mdi-magnify</v-icon></v-btn>
                            </template>
                        </v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Apellidos y Nombres:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.nombre_completo"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Carrera / Cargo:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.cargo_carrera"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" v-if="paciente.tipo == 'estudiante'" class="pa-1">
                    <div>
                        <label>Admision:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.admision"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Sede:<b class="red--text text-h6">*</b></label>
                        <v-select :rules="[rules.required]" dense outlined hide-details="auto" v-model="paciente.sede"
                            :items="sedes"></v-select>
                    </div>
                </v-col>


                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Lugar de nacimiento:</label>
                        <v-text-field dense outlined hide-details="auto"
                        v-model="paciente.lugar_nacimiento"></v-text-field>
                    </div>
                </v-col>



                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Procedencia (Residencia habitual):</label>
                        <v-text-field dense outlined hide-details="auto"
                        v-model="paciente.procedencia"></v-text-field>
                    </div>
                </v-col>


                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Número de celular:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.celular]" dense outlined hide-details="auto"
                            v-model="paciente.telefono"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12">
                    <div class="font-weight-bold text-subtitle-2">Contacto de emergencia:</div>
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="paciente.tipo == 'estudiante'" class="pa-1">
                    <div>
                        <label>Celular de contacto:</label>
                        <v-text-field dense outlined hide-details="auto"
                            v-model="paciente.contacto_emergencia"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" v-if="paciente.tipo == 'estudiante'" class="pa-1">
                    <div>
                        <label>Nombre de contacto:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.nombre_contacto"></v-text-field>
                    </div>
                    <v-divider></v-divider>
                </v-col>

                <v-col cols="12" v-if="paciente.tipo == 'estudiante'" class="pa-1">
                    <div>
                        <label>Celular de contacto 2:</label>
                        <v-text-field dense outlined hide-details="auto"
                            v-model="paciente.contacto_emergencia_2"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" v-if="paciente.tipo == 'estudiante'" class="pa-1">
                    <div>
                        <label>Nombre de contacto 2:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.nombre_contacto_emergencia_2"></v-text-field>
                    </div>
                    <v-divider></v-divider>
                </v-col>

                <v-col cols="12" md="4" class="pa-1">
                    <div>
                        <label>Sexo:<b class="red--text text-h6">*</b></label>
                        <v-select :items="['HOMBRE', 'MUJER']" :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.sexo"></v-select>
                    </div>
                </v-col>

                <v-col cols="12" md="4" class="pa-1">
                    <div>
                        <label>Fecha de nacimiento:<b class="red--text text-h6">*</b></label>
                        <v-text-field type="date" :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.fecha_nacimiento"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" md="4" class="pa-1">
                    <div>
                        <label>Peso:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.peso"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" md="4" class="pa-1">
                    <div>
                        <label>Talla:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.talla"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" md="8" class="pa-1">
                    <div>
                        <label>Tipo de sangre:<b class="red--text text-h6">*</b></label>
                        <v-text-field :rules="[rules.required]" dense outlined hide-details="auto"
                            v-model="paciente.tipo_sangre"></v-text-field>
                    </div>
                </v-col>


                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Vacuna covid:</label>
                        <v-radio-group v-model="paciente.vacuna_covid" row>
                            <v-radio
                             :value="true"
                             label="SI"
                            ></v-radio>
                            <v-radio
                             :value="false"
                             label="NO"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-col>


                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>Dosis de COVID:</label>
                        <v-text-field dense outlined hide-details="auto" 
                        v-model="paciente.dosis_covid" type="number"></v-text-field>
                    </div>
                </v-col>


                <v-col cols="12">
                    <div class="font-weight-bold text-subtitle-1">Antecedentes patológicos:</div>

                    <div class="font-weight-bold text-subtitle-2">Familiares:</div>
                    <v-divider></v-divider>
                </v-col>

                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>HTA:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.hta_fam"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>DM:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.dm_fam"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>Neoplasias:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.neoplasias_fam"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" md="6" class="pa-1">
                    <div>
                        <label>Otros:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.otros_fam"></v-text-field>
                    </div>
                    <v-divider></v-divider>
                </v-col>


                <v-col cols="12">
                    <div class="font-weight-bold text-subtitle-2">Personales:</div>
                    <v-divider></v-divider>
                </v-col>


                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Enfermedades:</label>
                        <v-text-field dense outlined hide-details="auto"
                            v-model="paciente.enfermedades_pers"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Alergias:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.alergias_pers"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Cirugias:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.cirugias_pers"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Neoplasias:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.neoplasias_pers"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Fracturas:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.fracturas_pers"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Hospitalizaciones:</label>
                        <v-text-field dense outlined hide-details="auto"
                            v-model="paciente.hospitalizaciones_pers"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Otros:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.otros_pers"></v-text-field>
                    </div>
                </v-col>

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Convulsiones:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.convulsiones"></v-text-field>
                    </div>
                </v-col>



                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Accidentes:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.accidentes"></v-text-field>
                    </div>
                </v-col>


               

                <v-col cols="12" class="pa-1">
                    <div>
                        <label>Observaciones:</label>
                        <v-text-field dense outlined hide-details="auto" v-model="paciente.observaciones"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-1">
                    <v-btn :disabled="!validateform" @click="addPaciente()" :loading="loadingadd" block
                        class="bgCTM white--text">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Filters from '@/modules/Shared/Filters/index.js'
export default {
    mixins: [Filters],
    props:{
        classWrapper: {
            type: String
        }
    },
    data() {
        return {
            validateform: true
        }
    },
    computed:{
        ...mapState('topico/Paciente', ['paciente', 'loadingadd', 'loadingFindDNI', ]),
        ...mapState('topico/utiles', ['sedes'])
    },
    methods:{
        ...mapActions('topico/Paciente', ['addPaciente', 'findPaciente', 'getApiData']),
    } 
}

</script>