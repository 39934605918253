<template>
  <v-container fluid :style="$vuetify.breakpoint.lgAndUp? 'height: 100vh': ''" 
  class="px-0 d-flex justify-center flex-wrap align-center align-self-center animate__animated animate__flipInX">
    <div
      class="d-flex flex-wrap align-cente justify-center"
    >
      <card-pabellones v-if="$can('create', 'hospedaje')" class="mr-1 mr-lg-5 mb-2" />
      <card-visitas v-if="$can('read', 'visitas') || $can('read', 'estudianteasistencia')" class="mr-1 mr-lg-5 mb-2" />
      <card-permisos
        v-if="$can('read', 'permisos')"
        class="mr-1 mr-lg-5 mb-2"
      />
      <card-topico v-if="$can('read', 'topico')" class="mr-1 mr-lg-5 mb-2"></card-topico>
      <card-psicologia v-if="$can('read', 'psicologia')" class="mr-1 mr-lg-5 mb-2"></card-psicologia>
      <card-comision-disciplina v-if="$can('read', 'disciplina')" class="mr-1 mr-lg-5 mb-2"></card-comision-disciplina>

      <card-comedor v-if="$can('read', 'comedor')" class="mr-1 mr-lg-5 mb-2"></card-comedor>

    </div>
  </v-container>
</template>

<script>
import CardComedor from "../components/CardComedor.vue";
import CardPabellones from "../components/CardPabellones.vue";
import CardVisitas from "../components/CardVisitas.vue";
import CardPermisos from "../components/CardPermisos.vue";
import CardAsistencia from "../components/CardAsistencia.vue";
import CardTopico from '../components/CardTopico.vue'
import CardPsicologia from "../components/CardPsicologia.vue";
import CardComisionDisciplina from "../components/CardComisionDisciplina.vue";

export default {
  components: {
    CardPabellones,
    CardVisitas,
    CardComedor,
    CardPermisos,
    CardAsistencia,
    CardTopico,
    CardPsicologia,
    CardComisionDisciplina
  },
};
</script>

<style>
.education {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.credentialing {
  --bg-color: #E53935;
  --bg-color-light: #e2fced;
  --text-color-hover: #ffffff;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
  --bg-color: #4527A0;
  --bg-color-light: #e2fced;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
  --bg-color: #002946;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #ffffff;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.psicologia-resources {
  --bg-color: #00C853;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #ffffff;
  --box-shadow-color: rgba(231, 255, 220, 0.48);
}

.comision-resources {
  --bg-color: #FF9800;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #ffffff;
  --box-shadow-color: rgba(255, 237, 220, 0.48);
}
 
.cardDash {
  width: 175px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.cardDash:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.cardDash:hover .overlay {
  transform: scale(4) translateZ(0);
}

.cardDash:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.cardDash:hover .circle:after {
  background: var(--bg-color-light);
}

.cardDash:hover p, .cardDash:hover span {
  color: var(--text-color-hover);
}

.cardDash:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.cardDash p {
  font-size: 25px;
  font-weight: bold;
  color: #4c5656;
  margin-top: 20px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}
.cardDash span {
  font-size: 11px;
  color: #4c5656;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 5px;
  left: 5px;
  transition: opacity 0.3s ease-out;
}

.circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 30px;
  left: 30px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}



/* Media Query para pantallas medianas */
@media (min-width: 1024px) {
  .cardDash{
    width: 180px;
    height: 250px;
  }
  .overlay {
  width: 100px;
  height: 100px;
  top: 40px;
  left: 30px;
  }
}


</style>
