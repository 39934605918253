<template>
  <div style="height: 100vh" class="d-flex align-center justify-center">
    <v-card elevation="0" height="100%" rounded="0">
      <v-form v-model="formValidate" :disabled="loadingSavePermiso">
        <v-card-title class="justify-center text-subtitle-2 font-weight-bold text-uppercase pt-0 pt-lg-4">
          {{
            permiso.idpermisosalida != -1
              ? "EDITAR PERMISO"
              : "REGISTRAR NUEVO PERMISO"
          }}
        </v-card-title>

        <v-card-text class="black--text font-weight-bold px-0 px-lg-3">
          <v-alert v-if="!alert.type" class="cyan lighten-5">
            <v-icon left color="cyan darken-4">mdi-information-box</v-icon>
            <small class="cyan--text text--darken-4 text-uppercase">
              Para registrar un permiso, el estudiante debe estar dentro de la sede.
            </small>
          </v-alert> 

          <v-alert v-if="alert.type" dense dismissible :type="alert.type" text>
            <template v-slot:close="{ toogle }">
              <v-icon @click="onCloseAlert(toogle)" :color="alert.type"
                style="cursor: pointer;">mdi-close-circle</v-icon>
            </template>
            {{ alert.message }}
          </v-alert>


          <div v-if="!validacion_visita" style="padding: 10px; background-color: rgba(241, 245, 196, 0.8); color: black; margin-bottom: 10px;">
            <div class="black--text font-weight-bold pa-1">
              IMPORTANTE
            </div>
            <div>
              🔹 Primer paso: En el campo DNI, presiona Enter o haz clic en el  icono de la lupa 🔍 para buscar al estudiante en el registro de ingreso a la sede.
            </div>
          </div>

          <div v-else-if="validacion_visita" style="padding: 10px; background-color: rgba(241, 245, 196, 0.8); color: black; margin-bottom: 10px;">
            <div class="black--text font-weight-bold pa-1">
              IMPORTANTE
            </div>
            <div>
              ✅ Segundo paso: Registra el permiso.
            </div>
          </div>


          <v-row class="px-5">
            <v-col cols="12" md="6">
              <v-switch inset hide-details="auto" class="mt-0" v-model="permiso.extemporaneo" :true-value="1"
                :false-value="0">
                <template v-slot:label>
                  <v-menu open-on-hover top offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="font-weight-bold text-subtitle-1">Permiso Extemporáneo</div>
                    </template>
                    <v-card width="300">
                      <v-card-title class="text-subtitle-1 blue-grey darken-1 py-2 font-weight-bold white--text">
                        Permiso Extemporáneo
                      </v-card-title>
                      <v-card-text class="black--text pt-2">
                        Habilitar esta opción únicamente cuando el estudiante ya ha abandonado la sede y se desea
                        registrar el permiso exclusivamente como historial.
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <div>
                <small>ES MENOR DE EDAD</small><b class="red--text text-h6">*</b>

                <v-radio-group v-model="permiso.es_menor" row dense class="mt-1">
                  <v-radio label="SI" value="SI"></v-radio>
                  <v-radio label="NO" value="NO"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div>
                <small>SEDE</small><b class="red--text text-h6">*</b>
                <v-select v-if="multisede" :rules="[rules.required]" placeholder="Selecciona una sede" dense
                  hide-details="auto" flat outlined class="grey lighten-4" v-model="permiso.sede"
                  :items="sedes"></v-select>
                <v-text-field v-else disabled :rules="[rules.required]" v-model="permiso.sede" class="grey lighten-4"
                  dense hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div>
                <small>DNI (Presione enter o en la lupa para buscar) </small><b class="red--text text-h6">*</b>
                <v-text-field autofocus :rules="[rules.required, rules.dni]" class="grey lighten-4" dense
                  hide-details="auto" placeholder="Ingrese DNI" flat outlined ref="FieldDNI" v-model="permiso.dni"
                  @keypress.enter="getVisitaEstudiante()">
                  <template v-slot:append>
                    <v-btn icon @click="getVisitaEstudiante()" :loading="loadingBusquedaEstudiante">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div>
                <small>APELLIDOS Y NOMBRES {{ validacion_visita || "PERU"}} </small><b class="red--text text-h6">*</b>
                <v-text-field :disabled="!validacion_visita" :rules="[rules.required]" v-model="permiso.nombrecompleto"
                  class="grey lighten-4" dense hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div>
                <small>CARRERA</small><b class="red--text text-h6">*</b>
                <v-text-field :disabled="!validacion_visita" :rules="[rules.required]" v-model="permiso.carrera"
                  class="grey lighten-4" dense hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="6" class="py-0">
              <div>
                <small>ADMISIÓN</small><b class="red--text text-h6">*</b>
                <v-text-field :disabled="!validacion_visita" :rules="[rules.required]" v-model="permiso.admision"
                  class="grey lighten-4" dense hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>


            <v-col cols="6" class="py-0">
              <div>
                <small>SALIDA POR SUSPENSIÓN</small><b class="red--text text-h6">*</b>

                <v-radio-group v-model="permiso.suspension" row dense class="mt-1">
                  <v-radio label="SI" value="SI"></v-radio>
                  <v-radio label="NO" value="NO"></v-radio>
                </v-radio-group>
              </div>
            </v-col>

            <v-col cols="12" class="py-0">
              <div>
                <small>PERMISO EN HORARIO ACADÉMICO</small><b class="red--text text-h6">*</b>

                <v-radio-group v-model="permiso.horario_academico" row dense class="mt-1">
                  <v-radio label="Dentro del horario" style="font-size: 12px;" value="DENTRO"></v-radio>
                  <v-radio label="Fuera del horario" value="FUERA"></v-radio>
                </v-radio-group>
              </div>
            </v-col>

            <v-col cols="12" class="py-0">
              <div>
                <small>MOTIVO:</small><b class="red--text text-h6" v-if="permiso.suspension == 'NO'">*</b>
                <v-textarea v-if="permiso.suspension == 'NO'" :rules="[rules.required]" v-model="permiso.motivo"
                  class="grey lighten-4" dense hide-details="auto" flat outlined rows="2"></v-textarea>

                <v-textarea v-else disabled v-model="permiso.motivo" class="grey lighten-4" dense hide-details="auto"
                  flat outlined rows="2"></v-textarea>
              </div>
            </v-col>

            <v-col cols="12" class="py-0">
              <div>
                <small>LUGAR A DONDE SE DIRIGE:</small><b class="red--text text-h6">*</b>
                <v-text-field v-if="permiso.suspension == 'NO'" :rules="[rules.required]" v-model="permiso.lugar_dirige"
                  class="grey lighten-4" dense hide-details="auto" flat outlined></v-text-field>

                <v-text-field v-else disabled v-model="permiso.lugar_dirige" class="grey lighten-4" dense
                  hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="6" class="py-0">
              <div>
                <small>FECHA QUE SALDRÁ DE SEDE:</small><b class="red--text text-h6">*</b>
                <v-text-field :rules="[rules.required]" v-model="permiso.fecha_salida" type="date"
                  class="grey lighten-4" dense hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="6" class="py-0">
              <div>
                <small>HORA QUE SALDRÁ DE SEDE:</small><b class="red--text text-h6">*</b>
                <v-menu ref="menuPickerHour1" v-model="menuPickerHour" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="permiso.hora_salida" transition="scale-transition" offset-y max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :rules="[rules.required]" v-model="permiso.hora_salida" type="time"
                      class="grey lighten-4" dense hide-details="auto" flat outlined readonly v-bind="attrs" v-on="on"
                      prepend-icon="mdi-clock-time-four-outline"></v-text-field>
                  </template>
                  <v-time-picker v-if="menuPickerHour" v-model="permiso.hora_salida" full-width
                    @click:minute="$refs.menuPickerHour1.save(permiso.hora_salida)"></v-time-picker>
                </v-menu>

              </div>
            </v-col>

            <v-col cols="6" class="py-0">
              <div>
                <small>FECHA QUE RETORNA A SEDE:</small>
                <v-text-field v-model="permiso.fecha_ingreso" type="date" class="grey lighten-4" dense
                  hide-details="auto" flat outlined></v-text-field>
              </div>
            </v-col>

            <v-col cols="6" class="py-0">
              <div>
                <small>HORA QUE RETORNA A SEDE:</small>
                <v-menu ref="menuPickerHour2" v-model="menuPickerHour2" :close-on-content-click="false"
                  :nudge-right="40" :return-value.sync="permiso.hora_ingreso" transition="scale-transition" offset-y
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="permiso.hora_ingreso" type="time" class="grey lighten-4" dense
                      hide-details="auto" flat outlined readonly v-bind="attrs" v-on="on"
                      prepend-icon="mdi-clock-time-four-outline"></v-text-field>
                  </template>
                  <v-time-picker v-if="menuPickerHour2" v-model="permiso.hora_ingreso" full-width
                    @click:minute="$refs.menuPickerHour2.save(permiso.hora_ingreso)"></v-time-picker>
                </v-menu>

              </div>
            </v-col>

            <v-col cols="12" class="py-2 text-center" v-if="permiso.idpermisosalida != -1 && permiso.documento != null">
              <v-btn small class="blue white--text mr-2" elevation="0" @click="dialogDocumento = !dialogDocumento">ver
                documento</v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
              <div v-if="permiso.documento == null || permiso.idpermisosalida == -1">
                <small>CARGAR DOCUMENTO (img / pdf):</small>
                <v-file-input truncate-length="15" outlined class="grey lighten-4" hide-details="auto" dense
                  accept="image/png, image/jpeg, image/jpg, application/pdf" name="evidencia_visita[]"
                  @change="onChangeDocumento" v-model="permiso.documento" ref="fileupload"></v-file-input>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center px-5 mx-5">
          <v-btn v-if="permiso.idpermisosalida == -1" class="bgCTM white--text" width="50%" elevation="0"
            @click="setPermisoAxios()" :disabled="!formValidate && !validacion_visita" :loading="loadingSavePermiso"
            block>REGISTRAR</v-btn>
          <v-btn v-else class="bgCTM white--text" width="50%" elevation="0" @click="setActualizarPermiso()"
            :disabled="!formValidate" :loading="loadingSavePermiso" block>GUARDAR CAMBIOS</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <!--DIALOGO DE DOCUMENTO-->
    <v-dialog width="600" v-model="dialogDocumento">
      <v-card>
        <iframe :src="permiso.documento" width="100%" height="600" frameborder="0"></iframe>
      </v-card>
    </v-dialog>



  </div>
</template>

<script>
import Filters from "@/modules/Shared/Filters";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [Filters],

  data() {
    return {
      formValidate: true,
      dialogDocumento: false,
      menuPickerHour: false,
      menuPickerHour2: false,
      time: null,
      multisede: false,
    };
  },
  watch: {
    'permiso.suspension': {
      handler(value) {
        if (value == 'SI') {
          this.permiso.motivo = "ESTUDIANTE SUSPENDIDO"
          this.permiso.lugar_dirige = "DOMICILIO"
        } else {
          this.permiso.motivo = ""
          this.permiso.lugar_dirige = ""
        }
      }
    }
  },
  computed: {
    ...mapState("permisos", ["permiso", "loadingBusquedaEstudiante", "alert", "loadingSavePermiso", "validacion_visita"]),
    ...mapState("permisos/utiles", ["sedes"]),
    ...mapState(['user'])
  },

  methods: {
    ...mapMutations("permisos", ["onChangeDocumento", "setValidationVisita"]),
    ...mapActions("permisos", ["setPermisoAxios", "setActualizarPermiso", "getVisitaEstudiante"]),

    onCloseAlert(callback) {
      this.alert.type = ""
      callback()

    }
  },

  mounted() {
    const sedes = this.user.roles.sedes.split(',')

    if (sedes.length > 1) {
      this.multisede = true
    } else {
      this.permiso.sede = sedes[0]
    }


    setValidationVisita(false)

  }


};
</script>
