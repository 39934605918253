<template>
    <div>

        <v-card elevation="0" class="my-2 blue-grey darken-3">
            <v-card-text class="blue-grey darken-4">
                <v-row class="px-2" dense>
                    <v-col cols="12" class="d-flex justify-space-between">
                        <div class="pa-1">
                            <div class="text-lg-h6 font-weight-bold white--text">CONTROL DE PERMISOS A ESTUDIANTES</div>
                        </div>
                        <div class="d-flex justify-space-between">
                            <v-btn v-if="$can('create', 'permisos')" @click="openFormNewPermiso()" elevation="0"
                                class="cyan darken-2 white--text mr-2"><v-icon left>mdi-expand-all</v-icon>NUEVO
                                PERMISO</v-btn>
                            <v-btn v-if="$can('read', 'permisos')" @click="downloadReportePermisosSalida()"
                                elevation="0" class="green accent-4 white--text mr-2"><v-icon
                                    left>mdi-file-excel-box</v-icon>DESCARGAR
                                REPORTE</v-btn>

                            <v-btn v-if="$can('create', 'permisos')" @click="openPermisoMasivo()"
                                elevation="0" class="cyan accent-3 mr-2"><v-icon
                                    left>mdi-format-list-group-plus</v-icon>PERMISOS MASIVOS</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <div class="d-flex justify-space-between">
                    <div class="pb-1">
                        <v-text-field v-model="filtersPermisos.nombreDni" clearable style="width: 400px"
                            class="mr-2 white" outlined dense hide-details placeholder="Buscar por DNI o Nombre"
                            @keypress.enter="getPermisoSalidasAxios()" @click:clear="clearNombreDni()"></v-text-field>
                    </div>
                    <div>
                        <div>
                            <v-form v-model="formvalidateFilterDate" class="d-flex">
                                <div>
                                    <v-text-field v-model="filtersPermisos.fecha_inicio" :rules="[rules.required]"
                                        clearable type="date" class="mr-2 white" label="De" outlined dense
                                        hide-details></v-text-field>
                                </div>
                                <div>
                                    <div class="d-flex">
                                        <v-text-field v-model="filtersPermisos.fecha_fin" :rules="[rules.required]"
                                            class="white" clearable type="date" label="Hasta" outlined dense
                                            hide-details></v-text-field>
                                        <v-btn @click="getPermisoSalidasAxios()" :disabled="!formvalidateFilterDate"
                                            style="width: 30px !important; min-width: 30px !important; min-height: 40px !important;"
                                            class="px-1 ml-1 bgCTM white--text"
                                            elevation="0"><v-icon>mdi-filter</v-icon></v-btn>
                                        <v-btn @click="resetFilterFecha()" :disabled="!formvalidateFilterDate"
                                            style="width: 30px !important; min-width: 30px !important; min-height: 40px !important;"
                                            class="px-1 ml-1 grey lighten-3 text-CTM"
                                            elevation="0"><v-icon>mdi-restore</v-icon></v-btn>
                                    </div>
                                </div>
                            </v-form>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-card elevation="0" class="my-2">

            <v-card-text>

                <scroller :widthScroll="widthTable" />

                <v-data-table :headers="headers" :items="lista_permisos" :loading="loading" height="600" id="wrapper2"
                    mobile-breakpoint="0" class="table-reference" :items-per-page="80" :options.sync="options"
                    :server-items-length="paginate.total" :mobile-breakpoint="0" :footer-props="{
                        'items-per-page-options': [80, 120, 200],
                    }" fixed-header>


                    <template v-slot:item.nombrecompleto="{ item }">
                        <span class="font-weight-bold"
                            :class="[item.estado == 'anulado' ? 'text-decoration-line-through' : '']">{{
                                item.nombrecompleto
                            }}</span>
                        <div class="d-block" :class="[item.estado == 'anulado' ? 'text-decoration-line-through' : '']">
                            <small>{{ item.carrera }}</small>
                        </div>
                    </template>

                    <template v-slot:item.admision="{ item }">
                        <div class="d-block" :class="[item.estado == 'anulado' ? 'text-decoration-line-through' : '']">
                            {{ item.admision }}
                        </div>
                    </template>

                    <template v-slot:item.sede="{ item }">
                        <span :class="[item.estado == 'anulado' ? 'text-decoration-line-through' : '']">{{ item.sede
                            }}</span>
                    </template>

                    <template v-slot:item.es_menor="{item}">
                        <v-chip label :class="item.es_menor == 'SI'?'red': 'grey'" class="white--text">{{ item.es_menor? item.es_menor : 'NO' }}</v-chip>
                    </template>

                    <template v-slot:item.motivo="{ item }">
                        <span :class="[item.estado == 'anulado' ? 'text-decoration-line-through' : '']">{{ item.motivo
                            }}</span>
                    </template>

                    <template v-slot:item.horario_academico="{ item }">
        

                        <v-chip v-if="item.horario_academico" :class="item.horario_academico && item.horario_academico == 'DENTRO' ? 'amber accent-2 font-weight-bold' : 'light-green lighten-5'">
                            {{item.horario_academico == 'FUERA' ? 'Fuera del horario ': "Dentro del horario"}}
                        </v-chip>
                    </template>

                    <template v-slot:item.documento="{ item }">
                        <v-btn v-if="item.documento != null"
                            @click="openDialogEvidenciaSalida(item.documento, item.idpermisosalida)" small
                            class="deep-purple darken-2 rounded white--text" elevation="0"><v-icon color="white" small
                                left>mdi-file-document-check</v-icon> Abrir</v-btn>
                        <v-btn v-else @click="openDialogEvidenciaSalida(null, item.idpermisosalida)" small elevation="0"
                            class="cyan accent-4 white--text">
                            <v-icon left>mdi-arrow-up-bold-box</v-icon> Subir
                        </v-btn>
                    </template>

                    <template v-slot:item.envidencia_ingreso="{ item }">
                        <v-btn v-if="item.evidencia_ingreso != null"
                            @click="openDialogEvidenciaIngreso(item.evidencia_ingreso, item.idpermisosalida)" small
                            class="deep-purple darken-2 rounded white--text" elevation="0">
                            <v-icon left>mdi-file-document-check</v-icon> Abrir
                        </v-btn>
                        <v-btn v-else small elevation="0"
                            @click="openDialogEvidenciaIngreso(null, item.idpermisosalida)"
                            class="blue accent-2 white--text">
                            <v-icon left>mdi-arrow-up-bold-box</v-icon> Subir
                        </v-btn>
                    </template>

                    <template v-slot:item.estado="{ item }">
                        <v-chip small
                            :class="[estadoChipColor(item.estado), 'rounded font-weight-bold text-uppercase']">{{
                                item.estado }}</v-chip>
                    </template>

                    <template v-slot:item.fecha_salida="{ item }">
                        <span>{{ formatFechaCustom(item.fecha_salida, 'DD/MM/YYYY') }}</span>
                        <span> / {{ formatFechaCustom(item.fecha_salida + ' ' + item.hora_salida, 'hh:mm a') }}</span>
                    </template>

                    <template v-slot:item.fecha_ingreso="{ item }">
                        <span>{{ formatFechaCustom(item.fecha_ingreso, 'DD/MM/YYYY') }}</span>
                        <span> / {{ formatFechaCustom(item.fecha_ingreso + ' ' + item.hora_ingreso, 'hh:mm a') }}</span>
                    </template>


                    <template v-slot:item.salida_sede="{ item }">
                        <span>{{ formatFechaCustom(item.salida_sede, 'DD/MM/YYYY hh:mm a') }}</span>
                    </template>

                    <template v-slot:item.retorno_sede="{ item }">
                        <span>{{ formatFechaCustom(item.retorno_sede, 'DD/MM/YYYY hh:mm a') }}</span>
                    </template>

                    <template v-slot:item.autorizado="{ item }">
                        <div class="py-3" style="width: 250px;">
                            <v-chip label small class="text-subtitle-2">{{ capitalizeWords(firtsName(item.users.nombres)
                                + ' ' +
                                formatUserName(item.users.apellidos))
                                }}</v-chip>
                        </div>
                    </template>

                    <template v-slot:item.actions="{ item, index }">
                        <div class="d-flex gap-2" :key="index">
                            <div v-if="$can('update', 'permisos')" class="d-flex gap-2">
                                <v-tooltip bottom v-if="item.estado == 'enviado'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" @click="openEditPermiso(item)" small icon
                                            class="amber accent-4 mr-1 rounded"><v-icon
                                                small>mdi-pencil</v-icon></v-btn>
                                    </template>
                                    <span>Editar permiso</span>
                                </v-tooltip>

                                <v-tooltip bottom
                                    v-if="item.estado == 'enviado' && fechaRetornoVencida(item.fecha_ingreso + ' ' + item.hora_ingreso) == 'no-vencida'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" @click="setAnularPermiso(item.idpermisosalida)"
                                            small icon class="blue-grey darken-2 mr-1 rounded"><v-icon small
                                                color="white">mdi-cancel</v-icon></v-btn>
                                    </template>
                                    <span>Anular permiso</span>
                                </v-tooltip>

                                <v-tooltip bottom
                                    v-if="(item.estado == 'enviado' || item.estado == 'iniciado') && fechaRetornoVencida(item.fecha_ingreso + ' ' + item.hora_ingreso) == 'vencida'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on"
                                            @click="setFinalizarPermiso(item.idpermisosalida)" small icon
                                            class="deep-orange  mr-1 rounded"><v-icon small
                                                color="white">mdi-stop</v-icon></v-btn>
                                    </template>
                                    <span>Finalizar permiso</span>
                                </v-tooltip>
                            </div>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click="openPreviewPermiso(item)" small icon class="indigo accent-4 rounded"
                                        v-bind="attrs" v-on="on"><v-icon small
                                            color="white">mdi-eye-outline</v-icon></v-btn>
                                </template>
                                <span>Ver detalles</span>
                            </v-tooltip>
                        </div>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>



        <!--------------------------------------------------------------------->
        <evidenciasEntradaSalida> </evidenciasEntradaSalida>

        <!--------------------------------------------------------------------->
        <AlertasPermisos></AlertasPermisos>

    </div>
</template>


<script>
import Filters from '@/modules/Shared/Filters'
import { mapActions, mapMutations, mapState } from 'vuex'
import Scroller from '@/modules/Shared/components/Scroller.vue'
import { socket } from '@/socket-cliente'
import AlertasPermisos from './alertas-permisos.vue'
import evidenciasEntradaSalida from './evidenciasEntradaSalida.vue'


export default {
    mixins: [
        Filters
    ],
    components: {
        Scroller,
        AlertasPermisos,
        evidenciasEntradaSalida
    },
    data() {
        return {
            formvalidateFilterDate: true,
            documentViewDialog: false,
            urlPdf: "",
            idpermisosalida: -1,
            headers: [
                {
                    text: " ",
                    value: "actions",
                    sortable: false,
                    width: "80px"
                },
                {
                    text: "DNI",
                    value: "dni",
                    sortable: false,
                    width: "80px"
                },
                {
                    text: "Estudiante",
                    value: "nombrecompleto",
                    sortable: false,
                    width: "400px"
                },
                {
                    text: "Admisión",
                    value: "admision",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "Es menor de edad",
                    value: "es_menor",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "Es menor de edad",
                    value: "es_menor",
                    sortable: false,
                    width: "100px"
                },
                
                {
                    text: "Motivo",
                    value: "motivo",
                    sortable: false,
                    width: "400px"
                },
                {
                    text: "SEDE",
                    value: "sede",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "Horario académico",
                    value: "horario_academico",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "Fecha y hora de salida programada",
                    value: "fecha_salida",
                    sortable: false,
                    width: "200px"
                },

                {
                    text: "Fecha y hora de retorno programado",
                    value: "fecha_ingreso",
                    sortable: false,
                    width: "200px"
                },

                {
                    text: "Estado",
                    value: "estado",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "Fecha y hora de salida de sede",
                    value: "salida_sede",
                    sortable: false,
                    width: "200px"
                },

                {
                    text: "Fecha y hora de retorno a sede",
                    value: "retorno_sede",
                    sortable: false,
                    width: "200px"
                },

                {
                    text: "Evidencia de salida",
                    value: "documento",
                    sortable: false,
                    width: "50px"
                },

                {
                    text: "Evidencia de ingreso",
                    value: "envidencia_ingreso",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: "Autorizado por:",
                    value: "autorizado",
                    sortable: false,
                    width: "50px"
                },

            ],
            widthTable: '',
            options: {},
        }
    },
    watch: {
        'lista_permisos': {
            handler(value) {
                if (value) {
                    this.documentViewDialog = false;
                }


            }
        },
        options: {
            handler(option) {
                if (option) {
                    this.option.page = option.page;
                    this.option.itemsPerPage = option.itemsPerPage;
                    this.getPermisoSalidasAxios(true);
                }
            },
        },
    },
    computed: {
        ...mapState('permisos', ['lista_permisos', 'loading', 'filtersPermisos', 'option', 'paginate', 'dialgoEvidenciaSalidaPermisos', 'dialgoEvidenciaIngresoPermisos']),
        ...mapState(['user'])
    },
    methods: {
        ...mapMutations('permisos', ['openNuevoPermiso', 'resetFilters', 'resetPermiso', 'openEditPermiso', 'openPreviewPermiso', 'openPermisoMasivo']),
        ...mapActions('permisos', ['getPermisoSalidasAxios', "setAnularPermiso", 'setEliminarDocumentoPermiso', 'downloadReportePermisosSalida', 'setFinalizarPermiso']),

        openDialogEvidenciaSalida(url, id) {
            this.dialgoEvidenciaSalidaPermisos.openDialog = true
            this.dialgoEvidenciaSalidaPermisos.urlPDF = url
            this.dialgoEvidenciaSalidaPermisos.idpermisosalida = id
        },


        openDialogEvidenciaIngreso(url, id) {
            this.dialgoEvidenciaIngresoPermisos.openDialog = true
            this.dialgoEvidenciaIngresoPermisos.urlPDF = url
            this.dialgoEvidenciaIngresoPermisos.idpermisosalida = id
        },

        estadoChipColor(stateString) {
            let estado = stateString.toUpperCase();
            if (estado == 'ENVIADO') return 'blue accent-4 white--text'
            else if (estado == 'INICIADO') return 'green accent-3 white--text'
            else if (estado == 'FINALIZADO') return 'blue-grey lighten-1 white--text '
            else if (estado == 'ANULADO') return 'grey darken-4 white--text'

        },

        clearFilter() {
            this.resetFilters()
            this.getPermisoSalidasAxios()
        },

        clearNombreDni() {
            this.filtersPermisos.nombreDni = "";
            this.getPermisoSalidasAxios();
        },
        openFormNewPermiso() {
            this.resetPermiso(this.user.roles.sedes.split(','));
            this.openNuevoPermiso();
        },

        resetFilterFecha() {
            this.resetFilters()
            this.getPermisoSalidasAxios()
        },

        calcularWidthTable() {
            let table = document.querySelector('.v-data-table .v-data-table__wrapper table')
            this.widthTable = table.scrollWidth + 'px'
        },

        fechaRetornoVencida(fecha_retorno) {
            const fechaActual = moment();
            const fechaIngresada = moment(fecha_retorno);
            if (fecha_retorno) {

                if (fechaIngresada.isAfter(fechaActual)) {
                    // Fecha de retorno aun no vence
                    return 'no-vencida'

                } else if (fechaIngresada.isSameOrBefore(fechaActual)) {
                    // fecha de retorno vencida
                    return 'vencida'
                }
            }
        }
    },


    mounted() {
        this.filtersPermisos.sedes = this.$store.state.user.roles.sedes.split(',');
        this.getPermisoSalidasAxios(true);

        this.calcularWidthTable()

        //this.subscribeToEvents();

        socket.on('LOAD_ALL_PERMISOS', (data) => {
            this.getPermisoSalidasAxios(data);
        })

        socket.on('LOAD_ALL_VISITAS', (data) => {
            this.getPermisoSalidasAxios(data);
        })



    },

    updated() {
        this.calcularWidthTable()
    }


}
</script>