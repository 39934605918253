import axios from "axios";
import creadorPabellon from "./creadorPabellon";
import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import swal from "sweetalert";
export default {
  namespaced: true,
  modules: {
    creadorPabellon,
    utiles,
  },
  state: {
    tiposcama: ["cama", "camarote", "vacio", "muro"],
    pisos: ["1er piso", "2do piso", "3er piso", "4to piso", "5to piso"],
    tiposcama: [
      {
        text: "CAMA",
        value: "cama",
      },
      {
        text: "CAMAROTE",
        value: "camarote",
      },
      {
        text: "ESPACIO LIBRE",
        value: "vacio",
      },
      {
        text: "PARED",
        value: "muro",
      },
    ],
    estadoscama: ["disponible", "reporte", "inoperativa"],
    estadoscama: [
      {
        text: "CAMA LISTA",
        value: "disponible",
      },
      {
        text: "CAMA INOPERATIVA",
        value: "inoperativa",
      },

    ],
    sedes: ["ABQ", "IRQ", "FCHB", "COLQ"],
    tipoPersonal: ["alumnos", "colaboradores", "ambos"],
    genero: ["hombres", "mujeres"],

    selectedTemplate: "AyBrectangle",
    ubicacionBaño: [
      {
        text: "SALIDA IZQ - BAÑO DERECH",
        value: "bañoderecha",
      },
      {
        text: "SALIDA DERECH. - BAÑO IZQ.",
        value: "bañoizquierda",
      },
    ],

    

    listaPabellones: [],
    listaDeNombresDePabellones: [],
    loading: false
  },

  mutations: {
    setListaPabellones(state, payload) {
      state.listaPabellones = payload;
    },
    setListaNombresPabellones(state, payload) {
      state.listaDeNombresDePabellones = payload;
    },
  },

  actions: {
    getAxiosPabellones({ commit, state }) {
      state.loading = true
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "pabellones";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.params = { sedes : store.state.user.roles.sedes }
      utiles.state.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            console.log(resp)
            commit("setListaPabellones", resp.data.pabellones); 
            state.loading = false
          }
        })
        .catch((error) => {
          console.error(error)
          state.loading = false
        });
    },


    getListarPabellones({state, commit})
    {
      //listar-pabellones
      commit("utiles/resetConfigAxios");
      utiles.state.configAxios.url = utiles.state.apiUrl + "listar-pabellones";
      utiles.state.configAxios.method = "GET";
      utiles.state.configAxios.params = { sedes : store.state.user.roles.sedes }
      utiles.state.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
      axios(utiles.state.configAxios)
        .then((resp) => {
          if (resp.status == 200) {
            console.log(resp)
            commit("setListaNombresPabellones", resp.data);
            state.loading = false
          }
        })
        .catch((error) => {
          console.error(error)
          state.loading = false
        });

    },

    destroyPabellonAxios({commit, dispatch, state}, idpabellon){
      commit("utiles/resetConfigAxios");

      swal({
        title: "¿Deseas eliminar el pabellón?",
        icon: "warning",
        dangerMode: true,
        buttons: ['cancelar', 'confirmar']
      })
      .then(confirma => {

        if(!confirma){ return false}

        utiles.state.configAxios.url = utiles.state.apiUrl + "pabellones";
        utiles.state.configAxios.method = "DELETE";
        utiles.state.configAxios.data = { idpabellon : idpabellon }
        utiles.state.configAxios.headers.Authorization = "Bearer " + store.state.user.token;
        swal({
          title: "Cargando...",
          text: "Por favor, espera mientras se realiza la petición.",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true,
        });
        axios(utiles.state.configAxios)
          .then((resp) => {
            if (resp.status == 200) {
              console.log(resp)
              dispatch('getAxiosPabellones')
              swal({title: "Se eliminó correctamente el pabellón", icon:"success"})
            }
          })
          .catch((error) => {
            console.error(error)
            swal({title: "Ha ocurrido un error", icon:"error"})
          });

      })

    }
  },
};
